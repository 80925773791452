import React from "react"
import { motion } from "framer-motion"
import { StaticImage } from "gatsby-plugin-image"

// import Image from "./Image"

const container = {
  show: {
    transition: {
      staggerChildren: 0.5,
    },
  },
}

const item = {
  hidden: { opacity: 0, y: 200 },
  show: {
    opacity: 1,
    y: 0,
    transition: { ease: [0.6, 0.01, 0.05, 0.9], duration: 1.0 },
  },
  exit: {
    opacity: 0,
    y: -200,
    transition: {
      ease: "easeInOut",
      duration: 0.5,
    },
  },
}

const itemMain = {
  hidden: { opacity: 0, y: 200 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 1.0,
    },
  },
}

const Loader = ({ setLoading }) => {
  return (
    <motion.div layout className="loader w-screen h-screen">
      <motion.div
        variants={container}
        onAnimationComplete={() => setLoading(false)}
        initial="hidden"
        animate="show"
        exit="exit"
        className="loader-inner"
      >
        <ImageBlock variants={item} id="image-1">
          <StaticImage
            src="../assets/images/projects/image-1.svg"
            alt={"angga hermawan project developer skills"}
            placeholder="blurred"
          />
        </ImageBlock>

        <motion.div variants={itemMain} className="transition-image">
          <motion.img
            layoutId="main-image-1"
            src={require("../assets/images/projects/image-2.svg").default}
          />
        </motion.div>
        <ImageBlock variants={item} id="image-3">
          <StaticImage
            src="../assets/images/projects/image-3.svg"
            alt={"angga hermawan project developer skills"}
            placeholder="blurred"
          />
        </ImageBlock>
        <ImageBlock variants={item} id="image-4">
          <StaticImage
            src="../assets/images/projects/image-4.svg"
            alt={"angga hermawan project developer skills"}
            placeholder="blurred"
          />
        </ImageBlock>
        <ImageBlock variants={item} id="image-5">
          <StaticImage
            src="../assets/images/projects/image-5.svg"
            alt={"angga hermawan project developer skills"}
            placeholder="blurred"
          />
        </ImageBlock>
      </motion.div>
    </motion.div>
  )
}

export const ImageBlock = ({ posX, posY, variants, children, id }) => {
  return (
    <motion.div
      variants={variants}
      className={`image-block ${id}`}
      style={{
        top: `${posY}vh`,
        left: `${posX}vw `,
      }}
    >
      {/* <Image
        src={require(`../assets/images/projects/${id}.svg`).default}
        // fallback={require(`../assets/images/projects/${id}.jpg`).default}
        alt={"angga hermawan project developer skills"}
      /> */}
      {children}
    </motion.div>
  )
}

export default Loader
