import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import Quotes from "randomquote-api"

const banner = {
  animate: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1,
    },
  },
}

const letterAni = {
  initial: { y: 400 },
  animate: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 1,
    },
  },
}

const Banner = props => {
  const [playMarquee, setPlayMarquee] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setPlayMarquee(true)
    }, 2000)
  }, [])
  return (
    <motion.div layout className="banner" variants={banner}>
      <BannerRowTop title={"all"} />
      <BannerRowCenter title={"projects"} playMarquee={playMarquee} />
      <BannerRowBottom
        title={"   "}
        logoAfterBanner={props.logoAfterBanner}
      />
    </motion.div>
  )
}

const AnimatedLetters = ({ title, disabled }) => {
  return (
    <motion.span
      layout
      className="row-title"
      variants={disabled ? null : banner}
      initial="initial"
      animate="animate"
    >
      {[...title].map((letter, i) => (
        <motion.span
          key={letter + i}
          className="row-letter"
          variants={disabled ? null : letterAni}
        >
          {letter}
        </motion.span>
      ))}
    </motion.span>
  )
}

const BannerRowTop = ({ title }) => {
  const word = Quotes.randomQuote();
  return (
    <div className={"banner-row"}>
      <div className="row-col">
        <AnimatedLetters title={title} />
      </div>
      <motion.div
        layout
        initial={{ opacity: 0, y: 80 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          ease: "easeInOut",
          duration: 1,
          delay: 0.4,
        }}
        className="row-col"
      >
        <span className="row-message">
          {word.quote} - {word.author}
        </span>
      </motion.div>
    </div>
  )
}

const BannerRowCenter = ({ title, playMarquee }) => {
  return (
    <div className={`banner-row marquee  ${playMarquee && "animate"}`}>
      <motion.div
        initial={{ y: 310 }}
        animate={{ y: 0 }}
        transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1 }}
        className="marquee__inner"
      >
        <AnimatedLetters title={title} disabled />
        <AnimatedLetters title={title} />
        <AnimatedLetters title={title} disabled />
        <AnimatedLetters title={title} disabled />
      </motion.div>
    </div>
  )
}

const BannerRowBottom = ({ title, logoAfterBanner }) => {
  return (
    <div className={"banner-row center"}>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ ease: [0.6, 0.01, 0.05, 0.95], duration: 1, delay: 1 }}
        className="scroll"
      >
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 1.8,
          }}
        >
          scroll
        </motion.span>
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 1.8,
          }}
        >
          down
        </motion.span>
      </motion.div>
      <AnimatedLetters title={title} />
      {logoAfterBanner}
    </div>
  )
}

export default Banner
