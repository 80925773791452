import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import { BiLink } from "react-icons/bi"
import { format } from "date-fns"

import IntersectionObserver from "../IntersectionObserver"
import MotionDiv from "./MotionDiv"

const testMotion = {
  rest: {
    y: 0,
    transition: {
      duration: 2,
      type: "tween",
      ease: "easeIn",
    },
  },
  hover: {
    y: 5,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeOut",
      staggerChildren: 1,
    },
  },
}

const backgroundMotion = {
  rest: { opacity: 0, ease: "easeOut", duration: 0.2, type: "tween" },
  hover: {
    opacity: 1,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeIn",
      staggerChildren: 0.5,
    },
  },
}

const item = {
  rest: { opacity: 0, y: 200 },
  hover: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 1.6,
    },
  },
}

export default function ProjectDetail({ project, pathToImage, imgAlt }) {
  return (
    <IntersectionObserver key={project.id}>
      <MotionDiv>
        <div className="flex flex-col w-full h-full space-y-4">
          <motion.div
            className="flex-1 w-full content-project h-96"
            initial="rest"
            whileHover="hover"
            animate="rest"
          >
            {/* if svg using IMG html tag */}
            {/* <img src={project.image[0]?.file.url} alt='projects' /> */}
            <motion.div variants={testMotion} className="bg-[#F2F4FF]">
              <GatsbyImage image={pathToImage} style={{ objectFit: 'contain' }} className="w-full h-full max-h-96" alt={imgAlt} />
            </motion.div>

            <motion.div className="imgBckgrnd" variants={backgroundMotion}>
              <div className="project-details">
                <motion.div
                  className="flex flex-col space-y-1 project-details-top"
                  variants={item}
                >

                  <motion.p variants={item}>Location: {project.content.location}</motion.p>
                </motion.div>
                <motion.div variants={item}></motion.div>
              </div>
            </motion.div>
          </motion.div>
          <div className="project-details">
            <motion.div variants={item}>
              <motion.p variants={item}>{project.title}</motion.p>
              <motion.p variants={item}>{project.description.description}</motion.p>

              <motion.p variants={item}>
                status:{" "}
                {project.published && project.dateFinish
                  ? `published on ${format(
                      new Date(project.dateFinish),
                      "do MMMM, yyyy"
                    )}`
                  : `development start ${format(
                    new Date(project.dateStart),
                    "do MMMM, yyyy"
                  )}`}
              </motion.p>
            </motion.div>
            <motion.div variants={item}>
              {/* {project.content.links[0].backend && (
                <motion.a
                  variants={item}
                  href={project.content.links[0].backend}
                >
                  <BiLink /> Admin
                </motion.a>
              )} */}
              {project.content.links[0].frontend && (
                <motion.a
                  variants={item}
                  href={project.content.links[0].frontend}
                >
                  <BiLink /> Preview
                </motion.a>
              )}
            </motion.div>
          </div>
        </div>
      </MotionDiv>
    </IntersectionObserver>
  )
}
